import { debounce } from "debounce";

const iconList = document.querySelectorAll(".icon");
const videoElements = document.querySelectorAll("video");
const textElements = document.querySelectorAll(".text");

function toggleIcon(_thisIcon: Element) {
    const thisIconId = _thisIcon.getAttribute("id");
    const thisIconNewState = !_thisIcon.classList.contains("active");

    iconList.forEach((icon) => {
        const otherIconId = icon.getAttribute("id");
        // if icon of same kind, mirror state
        if (otherIconId === thisIconId) {
            icon.classList.toggle("active", thisIconNewState);
        } else if (otherIconId === "mute") {
        } else {
            icon.classList.remove("active");
        }
    });
}

function showVideo() {
    videoElements.forEach((video) => video.classList.remove("transparent"));
}

function hideVideo() {
    videoElements.forEach((video) => video.classList.add("transparent"));
}

function hideAllTexts() {
    textElements.forEach((text) => text.setAttribute("hidden", undefined));
}

function showText(text: Element) {
    text.removeAttribute("hidden");
}

function toggleAudio(videoElement: HTMLVideoElement, shouldMute) {
    videoElement.muted = shouldMute;
}

(async function removeVideoControlsOnIdle() {
    console.log("remove controls");
    const controlElements = document.querySelectorAll("div.controls");
    const toggleControls = (toggle: boolean) => () => {
        controlElements.forEach((element) => {
            element.toggleAttribute("hidden", toggle);
        });
    };
    const hideControlsAfterTimeoutPassed = debounce(toggleControls(true), 10 * 1000);
    const showControls = toggleControls(false);

    const handleMouseMove = () => {
        hideControlsAfterTimeoutPassed();
        showControls();
    };

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("touchmove", handleMouseMove);
    window.addEventListener("touchstart", handleMouseMove);
    hideControlsAfterTimeoutPassed();

    console.log("trigger ");
})();

videoElements.forEach((video) => {
    const icon = video.parentElement.querySelector(".icon[id=mute]");
    video.addEventListener("volumechange", () => {
        console.log("changed");
        icon.classList.toggle("active", !video.muted);
    });
});

iconList.forEach((icon) =>
    icon.addEventListener("click", (evt: Event) => {
        // console.log("clock");
        const videoElement =
            icon.parentElement.parentElement.querySelector("video");
        if (!videoElement) throw Error("html structure invalid");
        const isVideoActive = document.body.classList.contains("active");
        if (!isVideoActive) {
            return;
        }
        const targetTextId = icon.getAttribute("id");
        const targetTextElement = document.querySelector(
            `#text_${targetTextId}`
        );
        const isMuteIcon = icon.classList.contains("mute");
        const isIconActive = !icon.classList.contains("active");
        toggleIcon(icon);

        if (isMuteIcon) {
            toggleAudio(videoElement, !isIconActive);
            showVideo();
        } else {
            if (isIconActive) {
                hideVideo();
                hideAllTexts();
                showText(targetTextElement);
            } else {
                showVideo();
                hideAllTexts();
            }
        }
    })
);
